import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import Footer from "../components/footer";
import NavBar from "../components/navbar";
import HomeVideo from "../components/homevideo";
import HomeDashboard from "../components/homedashboard";
import Arrow from "../template/ScrollToTopButton";
import ScrollImage from "../template/scroll-image";
import { Helmet } from "react-helmet";

function HomePage() {
  const setScrollY = useState(0)[1];
  const mainRef = useRef(null);

  const handleClick = () => {
    setScrollY(100);
    mainRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Add custom scroll bar styles with a delay
    const addScrollBarStyles = () => {
      setTimeout(() => {
        const style = document.createElement("style");
        style.innerHTML = `
          ::-webkit-scrollbar {
            width: 0.0001rem;
            background-color: transparent;
          }
          ::-webkit-scrollbar-thumb {
            background-color: transparent;
          }
        `;
        document.head.appendChild(style);
      }, 0);
    };

    addScrollBarStyles();

    // Cleanup function to remove the custom scroll bar styles when the component unmounts
    return () => {
      const style = document.querySelector("style");
      if (style) {
        document.head.removeChild(style);
      }
    };
  }, []);

  return (
    <html lang="en">
      <div
        style={{
          overflow: "hidden",
          overflowY: "hidden",
          scrollbarColor: "transparent",
        }}
      >
        <ScrollImage />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Diversified Conglomerates in Sri Lanka | Lalan Group</title>
          <meta
            name="title"
            content="Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
          <meta
            name="description"
            content="Founded in the year 1940, Lalan group owns over 46 subsidiaries making it among the largest diversified conglomerates in Sri Lanka. "
          />
          <meta
            name="keywords"
            content="Diversified Conglomerates in Sri Lanka, Lalan Group"
          />
          <link rel="canonical" href="https://www.lalangroup.com/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
          <meta
            property="og:description"
            content="Founded in the year 1940, Lalan group owns over 46 subsidiaries making it among the largest diversified conglomerates in Sri Lanka."
          />
          <meta property="og:url" content="https://www.lalangroup.com/" />
          <meta
            property="og:site_name"
            content="Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
          <meta
            property="og:image"
            content="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/Lalan_Group_Logo.webp"
          />
        </Helmet>

        <NavBar />

        <div style={{ position: "relative" }}>
          <HomeVideo />
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="mouse-color-home">
              <img
                onClick={handleClick}
                src="/images/gif-mouse.gif"
                alt="button"
                className="mouse-button"
                style={{ height: "90px", zIndex: 10, maxWidth: "100%" }}
              />
            </div>
          </div>
        </div>

        <div ref={mainRef} className="home-dash-color">
          <div
            ref={mainRef}
            style={{ marginLeft: "10px", marginRight: "10px" }}
          >
            {/* Main content */}
            <HomeDashboard />
          </div>
        </div>

        <Footer />
        <Arrow />
      </div>
    </html>
  );
}

export default HomePage;
