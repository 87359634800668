import "./homevideo.css";
import React from "react";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
//import Background from "../images/video-v6.mp4";

const HomeVideo = () => {
  // const videoUrl =
  //   "https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/videos/home-hero-video.webm";

  const videoUrl = "/images/home/home-hero-video.webm";

  return (
    <ParallaxProvider>
      <div
        className="video-pc"
        style={{
          overflow: "hidden",
          overflowY: "hidden",
          overflowX: "hidden",
          scrollbarColor: "transparent",
        }}
      >
        <div className="fullpage-video-set">
          <video
            autoPlay
            loop
            muted
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          >
            {/* <source
              src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/videos/home-hero-video.webm"
              type="video/webm"
            /> */}
            <source src="/images/home/home-hero-video.webm" type="video/webm" />
          </video>
          <div className="video-overlay">
            <p>
              <div className="optimanormal">
                A Diversified Conglomerate With a Difference
              </div>
            </p>
          </div>
        </div>
      </div>

      <div
        className="video-mobile"
        style={{
          overflow: "hidden",
          overflowY: "hidden",
          overflowX: "hidden",
          scrollbarColor: "transparent",
        }}
      >
        <div className="fullpage-video-set">
          <div className="background-video-set">
            <ParallaxBanner
              style={{
                aspectRatio: "2 / 1.124",
                width: "100%",
                height: "100vh",
              }}
            >
              <video
                autoPlay
                loop
                muted
                style={{ width: "100%", height: "100%", marginTop: "-140px" }}
              >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
                <div>
                  <h1 style={{ color: "transparent", fontSize: "0px" }}>
                    A Diversified Conglomerate With a Difference
                  </h1>
                </div>
              </video>
            </ParallaxBanner>
          </div>
          <div className="video-overlay">
            <p>
              <div className="optimanormal">
                A Diversified Conglomerate With a Difference
              </div>
            </p>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default HomeVideo;
