import React, { useState, useEffect } from "react";
//import vote from "../images/bestweb-24-vote4us.webp"; //  BestWeb - logo
import TopWeb from "../images/TopWeb-May-2024.webp"; //  TopWeb - logo
import FooterBar from "./homepage-copyright";
import "./footer.css";
const Footer = () => {
  //  BestWeb - start
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(window.innerWidth <= 950);

      const handleResize = () => {
        setIsMobile(window.innerWidth <= 950);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  //  BestWeb - end

  return (
    <div
      style={{
        backgroundColor: "#030017",
        opacity: "0.94",
        overflow: "hidden",
        overflowY: "hidden",
        overflowX: "hidden",
        scrollbarColor: "transparent",
      }}
    >
      <br />
      <div className="footer-PC">
        <br />
        <table style={{ width: "100%", marginTop: "50px" }}>
          <tbody>
            <tr>
              {/* BestWeb - start */}

              <td style={{ width: isMobile ? "60%" : "80%" }}>
                <div
                  className="optima2"
                  style={{
                    color: "#dbdbdb",
                    marginTop: "0px",
                    marginLeft: isMobile ? "20%" : "10%",
                    textAlign: "start",
                  }}
                >
                  Lalan Group is one of Sri Lanka's largest diversified
                  conglomerates with subsidiaries in plantations, manufacturing,
                  logistics, printing and packaging, engineering services, and
                  hospitality.
                </div>
              </td>
              <td
                style={{
                  textAlign: "start",
                  marginRight: "10%",
                  justifyItems: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <div className="logoimage-container-2">
                  {/* <a
                    href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
                    target="_blank"
                  >
                    <img
                      src={vote}
                      alt="BestWeb"
                      style={{ height: "110px", marginTop: "10px" }}
                    />
                  </a> */}
                  <a
                    href="https://topweb.lk/may2024/lalangroup"
                    target="_blank"
                  >
                    <img
                      src={TopWeb}
                      alt="TopWeb"
                      style={{
                        height: "100px",
                        marginLeft: "30px",
                        marginRight: "30px",
                        borderRadius: "5%",
                      }}
                    />
                  </a>
                </div>
              </td>
              {/* BestWeb - end */}

              {/* <div className="footer-tp">
                <div
                  className="optima2"
                  style={{
                    color: "#dbdbdb",
                    marginTop: "120px",
                    marginRight: "170px",
                    marginLeft: "170px",
                    textAlign: "start",
                  }}
                >
                  Lalan Group is one of Sri Lanka's largest diversified
                  conglomerates with subsidiaries in plantations, manufacturing,
                  logistics, printing and packaging, engineering services, and
                  hospitality.
                </div>
              </div> */}
            </tr>
          </tbody>
        </table>
      </div>

      <div className="footer-mobile">
        <div
          className="optima2"
          style={{
            color: "#dbdbdb",
            marginTop: "120px",
            marginRight: "50px",
            marginLeft: "70px",
            textAlign: "start",
            fontSize: "26px",
          }}
        >
          Lalan Group is one of Sri Lanka's largest diversified conglomerates
          with subsidiaries in plantations, manufacturing, logistics, printing
          and packaging, engineering services, and hospitality.
        </div>
        <div
          className="logoimage-container"
          style={{ marginTop: "100px", textAlign: "center" }}
        >
          {/* <a
            href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
            target="_blank"
          >
            <img src={vote} alt="BestWeb" style={{ marginLeft: "-5px" }} />
          </a> */}

          <a href="https://topweb.lk/may2024/lalangroup/" target="_blank">
            <img
              src={TopWeb}
              alt="TopWeb"
              style={{ width: "69px", marginLeft: "25px", borderRadius: "5%" }}
            />
          </a>
        </div>
      </div>

      <div
        className="button-bar-pc"
        style={{
          marginLeft: "50px",
          marginRight: "50px",
          marginTop: "70px",
          zIndex: "10",
        }}
      >
        <div className="containerfooter">
          <table className="footer-tab-p">
            <tbody>
              <tr>
                <td>
                  <a href="/" className="optimanormal-footerbar">
                    Home
                  </a>
                </td>
                <td>
                  <a href="/contact-us/" className="optimanormal-footerbar">
                    Contact&nbsp;Us
                  </a>
                </td>
                <td>
                  <a href="/about/" className="optimanormal-footerbar">
                    About&nbsp;Us
                  </a>
                </td>
                <td>
                  <a href="/">
                    <img
                      src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/Lalan_Group_Logo-white-new.webp"
                      alt="Logo"
                      style={{ marginRight: "20px" }}
                    />
                  </a>
                </td>
                <td>
                  <a href="/sitemap/" className="optimanormal-footerbar">
                    Sitemap
                  </a>
                </td>
                <td>
                  <a href="/privacy-policy/" className="optimanormal-footerbar">
                    Privacy&nbsp;Policy
                  </a>
                </td>
                <td>
                  <a href="/cookie-policy/" className="optimanormal-footerbar">
                    Cookie&nbsp;Policy
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="button-bar-tab"
        style={{
          marginLeft: "50px",
          marginRight: "50px",
          marginTop: "100px",
          marginBottom: "50px",
        }}
      >
        <div>
          <div className="footer-tab-h">
            <center>
              <a href="/" className="optimanormal-footerbar">
                Home
              </a>
              <a href="/contact-us/" className="optimanormal-footerbar">
                Contact Us
              </a>
              <a href="/about/" className="optimanormal-footerbar">
                About Us
              </a>
              <br />
              <a href="/">
                <img
                  src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/Lalan_Group_Logo-white-new.png"
                  alt="Logo"
                  style={{ marginRight: "20px" }}
                />
              </a>
              <br />
              <a href="/sitemap/" className="optimanormal-footerbar">
                Sitemap
              </a>
              <a href="/privacy-policy/" className="optimanormal-footerbar">
                Privacy Policy
              </a>
              <a href="/cookie-policy/" className="optimanormal-footerbar">
                Cookie Policy
              </a>
            </center>
          </div>
        </div>
      </div>

      <div
        className="button-bar-mobile"
        style={{
          marginLeft: "15px",
          marginRight: "15px",
          marginTop: "80px",
          marginBottom: "70px",
        }}
      >
        <div className="footer-tab-h">
          <center>
            <a href="/">
              <img
                src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/Lalan_Group_Logo-white-new.png"
                alt="Logo"
                style={{ marginRight: "30px" }}
              />
            </a>
            <br />
            <a href="/sitemap/" className="optimanormal-footerbar">
              Sitemap
            </a>
            <a href="/privacy-policy/" className="optimanormal-footerbar">
              Privacy Policy
            </a>
            <a href="/cookie-policy/" className="optimanormal-footerbar">
              Cookie Policy
            </a>
          </center>
        </div>
      </div>

      <div style={{ backgroundColor: "#030017", marginTop: "20px" }}>
        <FooterBar />
      </div>
    </div>
  );
};

export default Footer;
