import React, { useState, useEffect } from "react";
import "./scroll-image.css";

const ScrollImage = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className={`scroll-image-home ${isVisible ? "show" : ""}`}>
      <div className="time-pic" />
    </div>
  );
};

export default ScrollImage;
