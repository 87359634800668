import React from 'react';
import './homepage-copyright.css';

const Footerbar = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div style={{overflow: "hidden", overflowY: "hidden", overflowX:"hidden", scrollbarColor: "transparent"}}>
      

      <div className='home-footer-pc'>
        <div className="home-footer">
        <div className="container">
          <p className="home-footer-text">
            <a style={{marginRight: "100px"}} className="home-footer-text" href='https://www.3cs.lk/'>
              Copyright &copy; {currentYear}  Lalan Group. site by 3CS.
            </a>
          </p>
        </div>
        </div>
      </div>


      <div className='home-footer-mobile'>
        <div className="home-footer">
        <div className="container">
          <p className="home-footer-text">
            <a style={{marginRight: "100px"}} className="home-footer-text" href='https://www.3cs.lk/'>
              Copyright &copy; {currentYear}  Lalan Group. site by 3CS.
            </a>
          </p>
        </div>
        </div>
      </div> 
    </div>
  );
};
export default Footerbar;