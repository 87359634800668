import React from "react";
import "./homedashboard.css";

const HomeDashboard = () => {
  return (
    <div
      style={{
        backgroundColor: "#04051d",
        overflow: "hidden",
        overflowY: "hidden",
        overflowX: "hidden",
        scrollbarColor: "transparent",
      }}
    >
      .
      <div className="container1" style={{ textAlign: "center" }}>
        <a
          href="https://www.lalanrubbers.com/"
          target="_blank"
          className="image1"
          style={{
            backgroundImage:
              "url(https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/grid/01.webp)",
            backgroundSize: "cover",
          }}
        >
          <h2
            className="mr-eaves-modern"
            style={{ color: "white", paddingTop: "270px", fontWeight: "bold" }}
          >
            Gloves
          </h2>
        </a>
        <a
          href="/sectors/plantations/"
          className="image2"
          style={{
            backgroundImage:
              "url(https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/grid/02-new.webp)",
            backgroundSize: "cover",
          }}
        >
          <h2
            className="mr-eaves-modern"
            style={{ color: "white", paddingTop: "270px", fontWeight: "bold" }}
          >
            Plantations
          </h2>
        </a>
      </div>
      <div className="container2" style={{ textAlign: "center" }}>
        <a
          href="/sectors/rubber-products/"
          className="one"
          style={{
            backgroundImage:
              "url(https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/grid/03.webp)",
            backgroundSize: "cover",
          }}
        >
          <h2
            className="mr-eaves-modern"
            style={{ color: "white", paddingTop: "605px", fontWeight: "bold" }}
          >
            Rubber Products
          </h2>
        </a>
        <a
          href="/sectors/packaging"
          className="two"
          style={{
            backgroundImage:
              "url(https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/grid/04.webp)",
            backgroundSize: "cover",
          }}
        >
          <h2
            className="mr-eaves-modern"
            style={{ color: "white", paddingTop: "270px", fontWeight: "bold" }}
          >
            Packaging
          </h2>
        </a>
        <a
          href="/sectors/renewable-energy/"
          className="three"
          style={{
            backgroundImage:
              "url(https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/grid/05.webp)",
            backgroundSize: "cover",
          }}
        >
          <h2
            className="mr-eaves-modern"
            style={{ color: "white", paddingTop: "270px", fontWeight: "bold" }}
          >
            Renewable Energy
          </h2>
        </a>
        <a
          href="/sectors/engineering/"
          className="four"
          style={{
            backgroundImage:
              "url(https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/grid/06-new.webp)",
            backgroundSize: "cover",
          }}
        >
          <h2
            className="mr-eaves-modern"
            style={{ color: "white", paddingTop: "270px", fontWeight: "bold" }}
          >
            Engineering
          </h2>
        </a>
        <a
          href="/sectors/thread&fabric/"
          className="five"
          style={{
            backgroundImage:
              "url(https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/grid/07-new.webp)",
            backgroundSize: "cover",
          }}
        >
          <h2
            className="mr-eaves-modern"
            style={{ color: "white", paddingTop: "605px", fontWeight: "bold" }}
          >
            Thread & Fabric
          </h2>
        </a>
        <a
          href="/sectors/leisure/"
          className="six"
          style={{
            backgroundImage:
              "url(https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/grid/08-new.webp)",
            backgroundSize: "cover",
          }}
        >
          <h2
            className="mr-eaves-modern"
            style={{ color: "white", paddingTop: "270px", fontWeight: "bold" }}
          >
            Leisure
          </h2>
        </a>
        <a
          href="/sectors/contract-manufacturing/"
          className="seven"
          style={{
            backgroundImage:
              "url(https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/grid/09.webp)",
            backgroundSize: "cover",
          }}
        >
          <h2
            className="mr-eaves-modern"
            style={{ color: "white", paddingTop: "270px", fontWeight: "bold" }}
          >
            Contract Manufacturing
          </h2>
        </a>
      </div>
      <div className="container3" style={{ textAlign: "left" }}>
        <a
          href="/sustainability/leaf/"
          className="con3-one"
          style={{
            backgroundImage:
              "url(https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/home/tile-3-a-new.webp)",
            backgroundSize: "cover",
          }}
        >
          <h2
            className="mr-eaves-modern"
            style={{
              color: "white",
              paddingTop: "230px",
              marginLeft: "60px",
              fontWeight: "bold",
            }}
          >
            Lalan Environmental Action Fund
          </h2>
        </a>
        <a
          href="/sustainability/lalith-hapangama-foundation/"
          className="con3-two"
          style={{
            backgroundImage:
              "url(https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/home/tile-3-b-new.webp)",
            backgroundSize: "cover",
          }}
        >
          <h2
            className="mr-eaves-modern"
            style={{
              color: "white",
              paddingTop: "230px",
              marginLeft: "60px",
              fontWeight: "bold",
            }}
          >
            Lalith Hapangama Foundation
          </h2>
        </a>
        <a
          href="/aboutus/timeline/"
          className="con3-three"
          style={{
            backgroundImage:
              "url(https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/home/tile-3-c-new.webp)",
            backgroundSize: "cover",
          }}
        >
          <h2
            className="mr-eaves-modern"
            style={{
              color: "white",
              paddingTop: "230px",
              marginLeft: "60px",
              fontWeight: "bold",
            }}
          >
            Timeline
          </h2>
        </a>
      </div>
    </div>
  );
};

export default HomeDashboard;
